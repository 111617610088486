import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" header={ { siteTitle: 'LT Italia' } }/>
    <div className={'container text-center my-5 py-5'}>
      <h1>NOT FOUND</h1>
      <p>This page doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
